import { Platform } from '@ionic/angular/standalone';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Device } from '@capacitor/device';

import { environment } from '@environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  public isStandalone =
    navigator['standalone'] ||
    window.matchMedia('(display-mode: standalone)').matches;
  private isMobile = new BehaviorSubject<boolean>(false);
  public isMobile$ = this.isMobile.asObservable();
  static deviceId: string;

  constructor(
    private platform: Platform,
    private localStorageService: LocalStorageService
  ) {
    this.isMobile.next(this.checkIsMobile());
    this.platform.resize.subscribe(async () =>
      this.isMobile.next(this.checkIsMobile())
    );

    console.log('isStandalone', this.isStandalone);
  }

  async init() {
    // getDeviceID is sync to allow http interceptor to
    // get the value sync for http headers
    // we therefore init the value for the whole session here.
    if (this.platform.is('capacitor')) {
      DeviceService.deviceId = (await Device.getId()).identifier;
      console.log('capacitor deviceId', DeviceService.deviceId);
    } else {
      DeviceService.deviceId = await this._getWebDeviceId();
      console.log('web deviceId', DeviceService.deviceId);
    }
  }

  isiOSMobile() {
    return this.platform.is('mobileweb') && this.platform.is('ios');
  }

  isiOSSafariMobile() {
    return (
      this.platform.is('mobileweb') &&
      this.platform.is('ios') &&
      this.platform.testUserAgent('Safari') &&
      !this.platform.testUserAgent('Chrome') &&
      !this.platform.testUserAgent('CriOS')
    );
  }

  getDeviceID() {
    return DeviceService.deviceId;
  }

  private checkIsMobile() {
    return this.platform.width() <= environment.mobileBreakpoint;
  }

  private async _getWebDeviceId() {
    // @todo: remove this in a while
    // migrate old prefix to new one cap v2 => v3
    // it is here because loaded first via APP_INITIALIZER
    console.log('_getWebDeviceId');
    const cs3WebDeviceId = await this.localStorageService.get('cs3WebDeviceId');
    if (cs3WebDeviceId) return cs3WebDeviceId;

    const newId = `web_${UUID.UUID()}`;
    this.localStorageService.set('cs3WebDeviceId', newId);
    return newId;
  }
}
